import React,{useEffect} from "react";
import { Row, Col } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";
export default function ContactUs() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="aboutUsBannerImg">
        <div className="container">
          <div className="contactUs">
            <Row>
              <Col xl={6} md={6} lg={6} sm={12}>
                <div className="titleFont">
                  <h3>Demandez à être contacté par un de nos consultant</h3>
                </div>
                <form className="row g-3 needs-validation formContactus">
                  <div className="col-md-6  col-sm-6 col-6">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom01"
                      placeholder="Nom*"
                      required
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 col-6">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom02"
                      placeholder="Prénom*"
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom03"
                      placeholder="Email*"
                      required
                    />
                    <div className="invalid-feedback">
                      Please provide a valid Company Name.
                    </div>
                  </div>
                  <div className="col-md-12">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom03"
                      placeholder="Téléphone*"
                      required
                    />
                    <div className="invalid-feedback">
                      Please provide a valid Company Name.
                    </div>
                  </div>
                  <div className="col-md-12">
                    <textarea 
                      type="text"
                      className="form-control"
                      id="validationCustom03"
                      placeholder="Précisions sur votre demande*"
                      required
                    />
                    <div className="invalid-feedback">
                      Please provide a Send Meassage.
                    </div>
                  </div>

                  <div className="col-12 text-center">
                  <button className="ourSurvice px-5">Envoyer </button>
                  </div>
                </form>
              </Col>
              <Col xl={6} md={6} lg={6} sm={12}>
                <div className="quickContact">
                  <h2>Contact</h2>
                  <p>
                  Demandez à être contacté par un de nos consultant
                  </p>
                  <div className="contactUsIconContain">
                    <i className="fa fa-map-marker"></i>
                    <span>36 B, boulevard d’Anfa, Casablanca, Maroc</span>
                  </div>
                  <div className="contactUsIconContain">
                    <i className="fas fa-phone-alt"></i>
                    <span>+212 (0)5.22.22.12.19</span>
                  </div>
                  <div className="contactUsIconContain">
                    <i className="fas fa-envelope"></i>
                    <span>contact@svc.ma</span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
