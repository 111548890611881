import React from 'react'
import { Row, Col,Accordion } from "react-bootstrap";
import ImgMission from '../../../assets/Images/JobOffre/about-us-thumb.png'

export default function NosMission() {
  return (
    <div className="container mt-5">
        <h3 className="main-color text-center py-3 fw-bold"> Vos bénéfices </h3>
        <Row className="NosMission">
            <Col xl={6} lg={6} md={6}>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                        Votre rapport de personnalité
                        </Accordion.Header>
                        <Accordion.Body>
                        1 Rapport d’analyse Comportements et Valeurs
                        TTI Success Insight, leader mondial des analyses 
                        comportementales psychotechniques
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                        Votre livret de recherche d’emploi
                        </Accordion.Header>
                        <Accordion.Body>
                        1 livret reprenant les principes fondamentaux de la recherche d’emploi au Maroc et les contacts clés
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                        Votre simulation d’entretien en vidéo
                        </Accordion.Header>
                        <Accordion.Body>
                        1 clé USB  avec la vidéo de votre simulation d’entretien d’embauche ainsi que les conseils du débriefing
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>
                        Votre consultant à disposition
                        </Accordion.Header>
                        <Accordion.Body>
                        un suivi régulier sur l’état d’avancement de la recherche et un accompagnement supplémentaire au besoin (en cas d’entretien par exemple)
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Col>
            <Col xl={6} lg={6} md={6}>
              <img className="img-fluid mx-auto" alt="AboutUs" src={ImgMission} />
            </Col>
        </Row>    
    </div>    
  )
}
