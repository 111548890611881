import React,{useEffect} from "react";
import { Row, Col } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import catalogueImg from "../../../assets/Images/formation/catalogue.png";
export default function Form() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="aboutUsBannerImg mt-5">
        <div className="container">
          <div className="contactUs">
            <Row>
              <Col xl={6} md={6} lg={6} sm={12}>
                <div className="titleFont">
                  <h3 className="text-center">Pour recevoir notre catalogue de formation</h3>
                </div>
                <form className="row g-3 needs-validation formContactus">
                  <div className="col-md-6  col-sm-6 col-6">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom01"
                      placeholder="Nom*"
                      required
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 col-6">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom02"
                      placeholder="Prénom*"
                      required
                    />
                  </div>
                  <div className="col-md-12">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom03"
                      placeholder="Email*"
                      required
                    />
                    <div className="invalid-feedback">
                      Please provide a valid Company Name.
                    </div>
                  </div>
                  <div className="col-md-12">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom03"
                      placeholder="Téléphone*"
                      required
                    />
                    <div className="invalid-feedback">
                      Please provide a valid Company Name.
                    </div>
                  </div>
                  <div className="col-md-12">
                    <textarea 
                      type="text"
                      className="form-control"
                      id="validationCustom03"
                      placeholder="Précisions sur votre demande*"
                      required
                    />
                    <div className="invalid-feedback">
                      Please provide a Send Meassage.
                    </div>
                  </div>

                  <div className="col-12 text-center">
                  <button className="ourSurvice px-5">Envoyer </button>
                  </div>
                </form>
              </Col>
              <Col xl={6} md={6} lg={6} sm={12} className="catalogueImg">
                <div>
                    <img src={catalogueImg} className="img-fuild d-block mx-auto w-100" />
                 </div> 
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
