import team1 from '../../../assets/Images/Team/khouloud.jpg';
import team2 from '../../../assets/Images/Team/frederic.jpg';
import team3 from '../../../assets/Images/Team/Dounia.jpg';
export const OurTeamImg = [
  
  {
    id: 1,
    image: team1,
    title: "Khouloud MRABET",
    subtitle: "Consultante Recrutement",
  },
  {
    id: 2,
    image: team2,
    title: "Frederic DELIGNY",
    subtitle: "Consultant et Formateur spécialisé en Ressource Humaines et Relation Client",
  },
  {
    id: 3,
    image: team3,
    title: "Dounia MOUISSE",
    subtitle: "Chargée de Sourcing et de Recrutement",
  }
];
export default OurTeamImg;