import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import About1 from "../../../assets/Images/AboutUs/About1.png";
import About2 from "../../../assets/Images/AboutUs/About2.png";
import AboutSlider1 from "../../../assets/Images/AboutUs/AboutSlider1.png";
import AboutSlider2 from "../../../assets/Images/AboutUs/AboutSlider2.png";
import AboutSlider3 from "../../../assets/Images/AboutUs/AboutSlider3.png";
import AboutSlider4 from "../../../assets/Images/AboutUs/AboutSlider4.png";
import { Link } from "react-router-dom";
const settings = {
  dots: false,
  arrows: true,
  slide: ".slick-slideshow__slide",
  slidesToShow: 1,
  centerMode: true,
  centerPadding: "110px",
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 2,
        centerMode: false,
      },
    },
    {
      breakpoint: 800,
      settings: {
        centerMode: false,
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        centerMode: false,
        slidesToShow: 2,

      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        centerMode: false,
      },
    },
  ],
};
export default class SimpleSlider extends Component {
  render() {
    return (
      <>
      <div className="about">
        <div className="container">
          <div className="mainAboutUs">
            <Row>
              <Col xl={9} lg={9} className="mx-auto">
                <div className="aboutUs">
                  <div className="titleFont">
                    <h3>À Propos de nous </h3>
                    <h2>Notre Vision</h2>
                    <h5>
                    Le symbole de l’origami : tirant ses racines dans la Chine du VIème siècle, 
                    l’origami représente l’art du pliage de papier, 
                    utilisé à la fois dans une quête esthétique et à des fins pédagogiques 
                    pour l’enseignement des mathématiques, de façon ludique et poétique.
                    Les possibilités de réalisations à partir d’une simple feuille de papier sont infinies.
                    Nous nous retrouvons dans cette image car nous aimons, à force de travail, 
                    de patience et de subtilité, affirmer les potentialités d’une organisation 
                    et de ses hommes afin d’aboutir à une solution personnalisée, 
                    harmonieuse et fédératrice.
                    </h5>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      </>
    );
  }
}
