import React from "react";
import { Row, Col, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FormControl } from "react-bootstrap";
import icon from "../../../assets/Images/BrandLogo/icon.png";
export default function StartedToday() {
  return (
    <>
      <div className="startedToday">
        <div className="container">
          <div className="startedTodayMainDivFloat">
            <Row>
              <Col xl={1} lg={1} md={1} sm={1} className="startedicon">
                <div className="startedTodayMainDiv">
                  <img
                    className="img-fluid mx-auto"
                    alt="AboutUs"
                    src={icon}
                  />
                  
                </div>
              </Col>
              <Col xl={5} lg={5} md={5} sm={5} className="startedTodaySideTextCol">
                <div className="startedTodaySideText">
                  <p>
                  Inscription Newsletter
                  </p>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} >
                <div className="startedEmailBox">
                  <InputGroup className="">
                    <FormControl placeholder="Votre Email..." />
                    <button className="button">
                        Abonnez-vous 
                    </button>
                  </InputGroup>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
