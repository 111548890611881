import React, { Component } from 'react'
import Filere from './Filere';
import Method from './Method';
import Form from './Form';
import "../../../theme/css/Service.css";


export default class Index extends Component {
  render() {
    return (
      <>
      <div className="otherPageBanner servicesBanner">
            <div className="text-center">
            <h2 className='text-white fs-4'>
                Skills & Values Consulting vous propose des solutions Formation adaptées à 
                    <span className="d-block" >vos enjeux</span>
            </h2>
            </div>
        </div>
        <Filere />
        <Method />
        <Form /> 
      </>
    )
  }
}
