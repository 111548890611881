import client1 from '../../../assets/Images/JobOffre/client1.jpg';
import client2 from '../../../assets/Images/JobOffre/client2.png';
export const OurList = [
  {
    id: 1,
    title: "Un Designer UI/UX avec 2 ans d'expérience",
    description: "Les principales activités du périmètre sont les suivantes : o Gérer et contrôler les éléments de gestion administration",
    lieu:"Casablanca",
    created_at:"il y a 2 Jours",
    logo:client1
  },
  {
    id: 1,
    title: "Un Designer UI/UX avec 2 ans d'expérience",
    description: "Les principales activités du périmètre sont les suivantes : o Gérer et contrôler les éléments de gestion administration",
    lieu:"Casablanca",
    created_at:"il y a 2 Jours",
    logo:client2
  },
  {
    id: 1,
    title: "Un Designer UI/UX avec 2 ans d'expérience",
    description: "Les principales activités du périmètre sont les suivantes : o Gérer et contrôler les éléments de gestion administration",
    lieu:"Casablanca",
    created_at:"il y a 2 Jours",
    logo:client1
  },
  {
    id: 1,
    title: "Un Designer UI/UX avec 2 ans d'expérience",
    description: "Les principales activités du périmètre sont les suivantes : o Gérer et contrôler les éléments de gestion administration",
    lieu:"Casablanca",
    created_at:"il y a 2 Jours",
    logo:client2
  },
  {
    id: 1,
    title: "Un Designer UI/UX avec 2 ans d'expérience",
    description: "Les principales activités du périmètre sont les suivantes : o Gérer et contrôler les éléments de gestion administration",
    lieu:"Casablanca",
    created_at:"il y a 2 Jours",
    logo:client1
  },
  {
    id: 1,
    title: "Un Designer UI/UX avec 2 ans d'expérience",
    description: "Les principales activités du périmètre sont les suivantes : o Gérer et contrôler les éléments de gestion administration",
    lieu:"Casablanca",
    created_at:"il y a 2 Jours",
    logo:client2
  },
  {
    id: 1,
    title: "Un Designer UI/UX avec 2 ans d'expérience",
    description: "Les principales activités du périmètre sont les suivantes : o Gérer et contrôler les éléments de gestion administration",
    lieu:"Casablanca",
    created_at:"il y a 2 Jours",
    logo:client1
  },
 
];
export default OurList;