import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../components/pages/HomePage/Home";
import NosReferences from "../components/pages/NosReferences/NosReferences";
import Conseil from "../components/pages/Conseil";
import Formation from "../components/pages/Formation";
import OffresEmploi from "../components/pages/JobOffre";

export default function Index() {
 
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/accueil" element={<Home />} />        
        <Route path="/nos-references" element={< NosReferences />} />        
        <Route path="/conseil" element={< Conseil />} />        
        <Route path="/formation" element={< Formation />} />        
        <Route path="/offres-emploi" element={< OffresEmploi />} />        
        <Route component={OffresEmploi} />

      </Routes>
     </div>
  );
}
