import team1 from '../../../assets/Images/Clients/Samira-Dahbane.jpg';
import team2 from '../../../assets/Images/Clients/Anas-Bensouda.jpg';
import team3 from '../../../assets/Images/Clients/Mhamed-Chraib.jpg';
export const OurClientImg = [
  
  {
    id: 1,
    image: team1,
    title: "Samira DAHBANE",
    subtitle: "DRH Africa First Assist",
    avis:`Lorem ipsum, dolor sit amet consectetur adipisicing elit.`,
  },
  {
    id: 2,
    image: team2,
    title: "Anas BENSOUDA",
    subtitle: "DSI Synergies Progres",
    avis:`Lorem ipsum, dolor sit amet consectetur adipisicing elit.`,
  },
  {
    id: 3,
    image: team3,
    title: "Mhamed Chraib",
    subtitle: "BG Du Maroc",
    avis:`Lorem ipsum, dolor sit amet consectetur adipisicing elit.`,
  }
];
export default OurClientImg;