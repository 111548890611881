import React from "react";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ThemeLogo from "../../assets/Images/BrandLogo/logo.png";
import { useState } from "react";
const Header = () => {
  const [showDropdownC, setShowDropdownC] = useState(false);
  const [showDropdownA, setShowDropdownA] = useState(false);
  const [isOpen, setIsopen] = useState(false);
  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  }
  return (
    <>
      <div className="header sticky-top">
        <Navbar expand="lg" collapseOnSelect>
          <div className="container">
            <div className="inner-header">
              <Navbar.Brand href="/">
                <img
                  src={ThemeLogo}
                  alt="themeLogo"
                  className="img-fluid mx-auto WLogo"
                />
              </Navbar.Brand>
              <Button
                className="btn-navbar navbar-toggle"
                onClick={ToggleSidebar}
              >
                <i className="fa fa-bars" aria-hidden="true"></i>
              </Button>
              <div className={`sidebar ${isOpen === true ? "active" : " "}`}>
                <div id="mySidenav" className="sidenav menu-vertical">
                  <div className="close-nav hidden-md hidden-lg hidden-xl  ">
                    <div>
                      <span>Menu</span>
                    </div>
                    <div>
                      <Button
                        className="closebtn pull-right"
                        onClick={ToggleSidebar}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </Button>
                    </div>
                  </div>
             
                <div className="navDiv">
                  <Nav className="justify-content-center" style={{ flex: 1 }}>
                  <Nav.Link href="/">
                      <Link to="/">Notre ADN</Link>
                    </Nav.Link>
                      <Nav.Link href="/nos-references">
                      <Link to="/nos-references">Nos références</Link>
                    </Nav.Link>
                    <Nav.Link href="/conseil">
                      <Link to="/conseil">Conseil</Link>
                    </Nav.Link>
                    <Nav.Link href="/formation">
                      <Link to="/formation">Formation</Link>
                    </Nav.Link>
                   
                    <NavDropdown
                      title="Recrutement"
                      id="collasible-nav-dropdown"
                      show={showDropdownC}
                      onMouseLeave={() => setShowDropdownC(false)}
                      onMouseOver={() => setShowDropdownC(true)}
                    >
                      <NavDropdown.Item href="#faq">
                        <Link to="/faq">FAQ</Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#planing">
                        <Link to="/planing">Planing</Link>
                      </NavDropdown.Item>                      
                      <NavDropdown.Item href="#contact">
                        <Link to="/contact">Contact</Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#blog">
                        <Link to="/blog">Blog</Link>
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </div>
                </div>
              </div>
              <div
                className={`sidebar-overlay ${isOpen === true ? "active" : ""}`}
                onClick={ToggleSidebar}
              ></div>
             
              <button className="ourSurvice">
                <Link to="/offres-emploi">Offres d’emploi</Link>
              </button>
            </div>
          </div>
        </Navbar>
      </div>
    </>
  );
};
export default Header;
