import React, { Component } from 'react'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Row, Col } from "react-bootstrap";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import offres from './JobOffreList'


export default class JobOffre extends Component {
    render() {
    return (
        <div className="container mt-5">
        <h3 className="main-color text-center py-3"> Nos Offres D'Emploi </h3>
        <div className='container'>
            <Row className="justify-content-center">
                <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                    <TextField id="outlined-basic" label="Titre du poste" variant="outlined" className="searchPoste" />
                </Col>
                <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={Emplacement}
                    sx={{ width: '100%' }}
                    renderInput={(params) => <TextField {...params} 
                    label="Emplacement" 
                    />}
                />
                </Col>
                <Col xl={3} lg={3} md={3} sm={6} xs={6}>
                    <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={secteur}
                    sx={{ width: '100%' }}
                    renderInput={(params) => <TextField {...params} 
                    label="Sélectionnez un secteur" 
                    />}
                />
                </Col> 
                <Col xl={1} lg={1} md={1} sm={6} xs={6} className="searchIcon" >
                    <SearchIcon />
                </Col>        
            </Row>
            <div className="my-5">
            {offres.map((offre) => (
                <div className="weProBox my-3" >
                <div className="weProBoxImg">
                <img
                    className="img-fluid mx-auto"
                    alt="AboutUs"
                    src={offre.logo}
                />
                </div>
                <div className="weProBoxText">
                <h6>
                    {offre.title}
                </h6>
                <p className="paragraph">
                <span className="d-block my-1">
                     <span className="ms-1">
                        <AddLocationAltOutlinedIcon /> {offre.lieu}   
                    </span>
                    <span>
                        <CalendarMonthOutlinedIcon /> {offre.created_at}  
                    </span>   
                </span>
                    {offre.description}
                </p>
                <div className='text-end'>
                    <Button variant="contained">postuler</Button>
                </div>
                </div>
             </div>
            ))}
                <Stack spacing={2} className="justify-content-center paginationJob">
                <Pagination count={10} color="primary" />
                </Stack>
            </div>
        </div>
        </div>
    )
  }
}

const secteur = [
  'Agriculture / Pêche',
  'Agroalimentaire',
  'Autre Secteur',
  'Édition / Communication / Multimédia',
  'Électronique / Électricité',
  'Banque / Assurance',
  'Bois / Papier / Carton / Imprimerie',
  'BTP / Matériaux de construction',
  'Centre d’appel / Business Center',
  'Chimie / Parachimie',
  'Commerce / Négoce / Distribution',
  ];
const Emplacement=
[
  "Agadir",
  "Al hoceima",
  "Aoussered",
  "Assilah",
  "Autre ville",
  "Azrou",
  "Ben ahmed",
  "Beni mellal",
  "Benslimane",
  "Berkane",
  "Berrechid",
  "Boujdour",
  "Bouskoura",
  "Bouznika",
  "Casablanca",
  "Chafchaouen",
  "Dakhla",
  "El hajeb",
  "El jadida",
  "Errachidia",
  "Essaouira",
  ]
  