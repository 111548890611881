import React, { Component } from "react";
import Slider from "react-slick";
import BrandLogo1 from "../../../assets/Images/BrandServicesFinanciers/partie 4.jpg";

export default class Sav extends Component {
  render() {
    return (
      <>
        <div>
          <div className="brandLogo container d-flex justify-content-between align-items-center">
          <h2 className="fw-bold main-color py-5">
          Relation Client Autres services

          </h2>
          <img src={BrandLogo1}  className="w-70"/>
          </div>
        </div>
      </>
    );
  }
}
