import React from 'react'
import '../../../theme/css/Portfolio.css'
import PortfolioImg from './PortfolioImg';
export default function Index() {
    return (
        <>
        <div className="otherPageBanner portfolioBanner">
            <div className="text-center">
                <h6>Conseil</h6>
            </div>
        </div>
        <PortfolioImg/>
      </>
    )
}
