import React, {  useEffect } from "react";
import Slider from "react-slick";
import { Card, CardImg, Col } from "react-bootstrap";
import OurTeamImg from "../HomePage/OurTeamImg";
const settings = {
  dots: false,
  aroow: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
export default function OurTeam() {
  return (
    <>
      <div className="container">
        <div className="titleFont">
          <h3 className="fs-1 fw-bold">L’équipe</h3>
        </div>
        <div className="ourTeamMain">
          <Slider {...settings} className="row">
            {OurTeamImg.map((photo) => (
              <div key={photo.id}>
                <Col xl={12}>
                  <Card className="ourTeamCard">
                    <div className="ourTeamImgBox beffect">
                      <div className="bimg">
                        <CardImg
                          src={photo.image}
                          atl="ourteam"
                          className="img-fluid mx-auto"
                        />
                      </div>
                      <div className="ourTeamIcon">
                        <ul className="list-unstyled">
                          <li className="d-inline-block">
                            <a href="/#" className="d-block">
                              <i className="fa fa-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <Card.Body>
                      <Card.Title className="fw-bold">{photo.title}</Card.Title>
                      <Card.Subtitle>{photo.subtitle}</Card.Subtitle>
                    </Card.Body>
                  </Card>
                </Col>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
}
