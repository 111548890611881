import React from "react";
import FAQ from "../../../assets/Images/NotreAdn/svc.jpg";
import { Row, Col, Accordion } from "react-bootstrap";
function HomeFAQ() {
  return (
    <>
      <div className="container">
        <div className="spacing">
              <div className="titleFont text-capitalize">
                <h2 className="text-center"> Notre engagement citoyen</h2>
              </div>
          <Row className="homeFAQRow ">
          <Col xl={4} lg={4} md={4} sm={6}>
              <div className="weProBox">
                <div className="weProBoxText">
                  <h6> Nos engagements de tous les jours</h6>
                  <p className="paragraph">
                      0 papier <br />
                      Pas de CV imprimé
                  </p>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={4} sm={6}>
              <div className="weProBox">
                <div className="weProBoxText">
                  <h6>   Actions sociales</h6>
                  <p className="paragraph">
                  Caravanes <br />
                  Nettoyer plages  <br />
                  Don du sang
                  </p>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={4} sm={6}>
              <div className="weProBox">
                <div className="weProBoxText">
                  <h6>   Soutien aux associations</h6>
                  <p className="paragraph">
                  Environnement <br />
                  Actions sociales <br />
                  Scolarisarion <br />
                  Emplyabilité...
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default HomeFAQ;
