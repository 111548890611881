import React,{useEffect} from 'react'
import { Link } from 'react-router-dom';
import { Row,Col } from 'react-bootstrap'
import Aos from "aos";
import "aos/dist/aos.css";
export default function Filere() {
  useEffect(() => {
    Aos.init({ duration: 3500 });
  }, []);
    return (
        <>
        <div className='container'>
           <div className="servicesCards">
          <Row>
            <Col xl={4} lg={4} md={4} sm={6} xs={6}>
              <div className="featuresCard">
              <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                <div className="featuresCardContain">
                  <h3>Conception ou relooking sur mesure de vos modules de formation</h3>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={4} sm={6} xs={6}>
              <div className="featuresCard">
                <i class="fa fa-user-graduate"></i>
                <div className="featuresCardContain">
                  <h3>Formation en efficacité professionnelle et développement personnel</h3>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={4} sm={6} xs={6}>
              <div className="featuresCard">
              <i class="fa fa-chart-line"></i>
                <div className="featuresCardContain">
                  <h3>Ingénierie de formation</h3>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={4} sm={6} xs={6}>
              <div className="featuresCard">
              <i class="fa fa-chart-pie"></i>
                <div className="featuresCardContain">
                  <h3>Formation en Management</h3>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={4} sm={6} xs={6}>
              <div className="featuresCard">
              <i class="fa fa-users"></i>
                <div className="featuresCardContain">
                  <h3>Formation <br></br> en RH </h3>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={4} sm={6} xs={6}>
              <div className="featuresCard">
              <i class="fa fa-handshake-o" aria-hidden="true"></i>
                <div className="featuresCardContain">
                  <h3>Formations Relation Client</h3>
                </div>
              </div>
            </Col>
          </Row>
        </div> 
        </div>
        </>
    )
}
