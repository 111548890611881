import React, { Component } from 'react';
import "../../../../src/theme/css/JobOffre.css";

import JobOffre from './JobOffre'
import NosMission from './NosMission'
import Bilans from './Bilans'

export default class index extends Component {
  render() {
    return (
    <>
    <div className="otherPageBanner servicesBanner">
        <div className="text-center">
            <h2 className='text-white fs-4'>
                Espace Candidats & Offres d’emploi
            </h2>
        </div>
    </div>
    <NosMission />
    <Bilans />
    <JobOffre />
    </>
    )
  }
}
