import React, { Component } from "react";
import BrandLogo1 from "../../../assets/Images/BrandServicesFinanciers/partie 1.jpg";
export default class BrandServicesFinanciers extends Component {
  render() {
    return (
      <>
        <div>
          <div className="brandLogo container d-flex justify-content-between align-items-center">
          <h2 className="fw-bold main-color py-5">
            Services Financiers
          </h2>
           <img src={BrandLogo1} className="w-70" />
          </div>
        </div>
      </>
    );
  }
}
