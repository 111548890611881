import React, { Component } from 'react'
import formationImg from "../../../assets/Images/formation/formation.png";
import { Row, Col } from "react-bootstrap";

export default class Method extends Component {
  render() {
    return (
        <div className="container mt-5">
        <h5 className="main-color text-center py-3"> Pour nous, le succès d’une opération tient à la rencontre de trois éléments </h5>
            <div className='formation'>
              <Row>
              <Col xs={4} className="">
                <h5 className="mainTilte">
                procédure de design de la solution
                </h5>
                <span></span>
                <div className='text-center'>
                Respect d’une procédure 
                rigoureuse et reconnue pour
                l’élaboration le suivi et 
                l’évaluation de la formation
                </div>
              </Col>
              <Col xs={4} className="text-center">
                <div className='mainTiltecenter'>
                <h5 className="mainTilte ">
                exeprtise métier de l'animateur
                </h5>
                </div>
                <span></span>
                <div className='text-center'>
                formateur expert idoine séléctionné au sein de notre réseau sur la base de notre réseau sur la base de deux critères : ses capacités communicationnelles et de son attachement à transmettre des valeurs et de l"énergie
                </div>
              </Col>
              <Col xs={4} className="">
                <h5 className="mainTilte">
                Approche pédagogique
                </h5>
                <span></span>
                <div className='text-center'>
                Parcours pédagogique et techniques d'animation modernes favorisant l'interactivité et la mise en application immédiate des apports de la formation
                </div>

              </Col>

              </Row>
            </div>
        </div>
    )
  }
}
