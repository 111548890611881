import React, {  useEffect } from "react";
import Slider from "react-slick";
import { Card, CardImg, Col } from "react-bootstrap";
import OurClientImg from "./OurClientImg";
import "../../../theme/css/NosReference.css"

const settings = {
  dots: false,
  aroow: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
export default function Client() {
 
  return (
    <>
      <div className="container mt-5">
        <div className="titleFont">
          <h3 className="fs-1 fw-bold text-center">Nos Clients</h3>
        </div>
        <div className="ourClientMain">
          <Slider {...settings} className="row">
            {OurClientImg.map((photo) => (
              <div key={photo.id}>
                <Col xl={12} className="clientBox">
                  <Card className="ourClientCard">
                    <div className="ourClientImgBox beffectClient">
                      <div className="bimg">
                        <CardImg
                          src={photo.image}
                          atl="ourteam"
                          className="img-fluid mx-auto"
                        />
                      </div>
                      <div className="ourClientIcon">
                        <p className="text-white">
                        {photo.avis}
                        </p>
                      </div>
                    </div>

                    <Card.Body>
                      <Card.Title className="fw-bold">{photo.title}</Card.Title>
                      <Card.Subtitle>{photo.subtitle}</Card.Subtitle>
                    </Card.Body>
                  </Card>
                </Col>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
}
