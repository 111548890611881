import React, { useEffect } from "react";
import "../../../theme/css/AboutUs.css";
import Client from "./Client";
import Aos from "aos";
import "aos/dist/aos.css";
import BrandServicesFinanciers from "./BrandServicesFinanciers";
import SsiiEsn from "./SsiiEsn";
import Industries from "./Industries";
import Sav from "./Sav";
export default function NosReferences() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  
  return (
    <>
      <div className="otherPageBanner contactUsBanner">
        <div className="text-center">
          <h1 className="text-white text-center">Nos Références</h1>
        </div>
      </div>
      <div className="container">
          <Client />
      </div>
      <div className="spacing">
        <BrandServicesFinanciers />
      </div>
      <div className="spacing">
        <SsiiEsn />
      </div>  
      <div className="spacing">
        <Industries />
      </div>
      <div className="spacing">
        <Sav />
      </div>    
    </>
  );
}
