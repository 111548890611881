import React from "react";
import Banner from "../HomePage/Banner"
import OurFeature from "../HomePage/OurFeature"
import AboutUs from '../HomePage/AboutUs'
import OurTeam from "./OurTeam";
import ContactUs from "./ContactUs";
import Chiffres from './Chiffres'
import NotreAdn from './NotreAdn'
export default function Home() {
  return (
    <>
      <Banner/>
      <Chiffres/>
      <OurTeam/>
      <AboutUs/>
      <OurFeature/>
      <NotreAdn />
      {/* <YearOfExperience/>
      <OurBestServices/>
      <OurPricing/>
      <OurTestimonial/> */}
      <ContactUs/>  
      {/* <OurBlog/>
      <Brandlogo/> */}
    
    </>
  );
}
