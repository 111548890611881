import React,{useEffect} from "react";
import "../../../theme/css/Planing.css";
import { Row, Col } from "react-bootstrap";
export default function Chiffres() {
    
    return(
        <>
         <div>
          <h4 className="keytitle text-center pt-3">Quelques chiffres</h4>
        </div>
        <div className="container">
        <div className="planingIconMainDiv">
          <Row>
            <Col lg={4} xl={4} md={4} sm={6}>
              <div className="planingIcon">
                <i className="fas fa-solid fa-users"></i>
                <h2>
                    6
                </h2>
                <h6 className="mb-0">années animées</h6>
                <h6>par la méme passion</h6>
              </div>
            </Col>
            <Col lg={4} xl={4} md={4} sm={6}>
              <div className="planingIcon">
                <i className="fa fa-globe"></i>
                <h2>
                75
                  </h2>
                <h6>entreprises partenaires</h6>
              </div>
            </Col>
            <Col lg={4} xl={4} md={4} sm={6}>
              <div className="planingIcon">
                  <i className="fa fa-flag"></i>
                <h2> 
                11
                  </h2>
                <h6 className="mb-0">pays couverts</h6>
                <h6>en Afrique et en Eroupe</h6>
              </div>
            </Col>
          </Row>
        </div>
      </div>
        </>
    )

}