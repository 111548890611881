import React from 'react'
import { Row, Col } from "react-bootstrap";
import Question from '../../../assets/Images/JobOffre/company.jpg';


export default function Bilans() {
  return (
    <div className="bilan">
    <div className="container">
      <div className="mainAboutUs">
      <h4 className='fw-bold text-center'>
        « Choisissez un travail que vous aimez et vous n’aurez pas à travailler 
        un seul jour de votre vie. » 
      </h4>
        <Row>
        <Col xl={6} lg={6} className="BilanImg">
          <img className="img-fluid mx-auto" alt="AboutUs" src={Question} />
          

          </Col>
        <Col xl={6} lg={6}>
            <div className="aboutUs">
              <div className="titleFont">
                <h3 className="py-3">
                Vous envisagez une reconversion professionnelle ? 
                </h3>
                <h5>
                Notre méthodologie et nos outils de bilans d’orientation ont pour vocation à vous aider à identifier vos leviers de motivations, vos valeurs ainsi que vos compétences métiers et comportementales (soft-skills) afin de déterminer des scénarios de projets professionnels.
                </h5>
                <h3 className="py-3">
                Vous souhaitez découvrir de nouvelles possibilités ?
                </h3>
                <h5>
                Le bilan doit permettre de choisir votre futur poste ou d’orienter votre poste actuel, en convergence avec votre personnalité et vos convictions, afin de maximiser votre bien-être.
                <br></br>
                Accompagné par un coach, le bilan de compétences est une projection positive vers l’avenir intégrant un plan de développement personnel afin de piloter sa carrière de façon autonome.
                </h5>
              </div>
            </div>
          </Col>
          
         
        </Row>
      </div>
    </div>
  </div>
  )
}
