import React from "react";
import "../../../../src/theme/css/HomePage.css";
import Slide1 from "../../../assets/Images/Banner/Slider1.jpg";
import Slide2 from "../../../assets/Images/Banner/Slider2.jpg";
import Slide3 from "../../../assets/Images/Banner/Slider3.jpg";
import { Link } from "react-router-dom";
import Slider from "react-slick";
const settings = {
  dots: false,
  arrows: true,
  aroow: false,
  autoplay:true,
  infinite: true,
  speed: 500,
};
export default function Home() {
  return (
    <>
    <div className="slider">
    <Slider {...settings}>
              <div>
                  <div className="mainBannerDiv">
                    <img className="img-fluid mx-auto" src={Slide1} alt="BannerImage"  width="100%"/>
                    <div className="bannerContain">
                        <h1 className="text-uppercase main-color fw-bold">vos solutions recrutement</h1>
                        <h2 className="text-uppercase fw-bold text-white">
                          sur messure et optimissées pour votre besoin
                          <br />
                          parce que chaque position mérite d'étre occupée
                          <br />
                          par une perle rare
                        </h2> 
                      </div>
                   </div>
                </div>
                <div>
                  <div className="mainBannerDiv">
                    <img className="img-fluid mx-auto" src={Slide2} alt="BannerImage"  width="100%"/>
                    <div className="bannerContain">
                        <h1 className="text-uppercase main-color fw-bold">vos solutions recrutement</h1>
                        <h2 className="text-uppercase fw-bold text-white">
                          sur messure et optimissées pour votre besoin
                          <br />
                           parce que chaque position mérite d'étre occupée
                          <br />
                          par une perle rare
                        </h2> 
                      </div>
                   </div>
                </div>
                <div>
                  <div className="mainBannerDiv">
                    <img className="img-fluid mx-auto" src={Slide3} alt="BannerImage" width="100%" />
                    <div className="bannerContain">
                        <h1 className="text-uppercase main-color fw-bold">vos solutions recrutement</h1>
                        <h2 className="text-uppercase fw-bold text-white">
                          sur messure et optimissées pour votre besoin
                          <br />
                          parce que chaque position mérite d'étre occupée
                          <br />
                           par une perle rare
                        </h2> 
                      </div>
                   </div>
                </div>
              
      </Slider>
    </div>

    
    </>
  );
}
