import React,{useEffect} from "react";
import "../../../../src/theme/css/HomePage.css";
import { Row, Col } from "react-bootstrap";
import Feature1 from "../../../assets/Images/OurFeatures/network.png";
import Feature2 from "../../../assets/Images/OurFeatures/chair.png";
import Feature3 from "../../../assets/Images/OurFeatures/support.png";
import Feature4 from "../../../assets/Images/OurFeatures/idea.png";
import { Link } from "react-router-dom";
export default function OurFeature() {

  return (
    <>
      <div className="container">
        <div className="ourFeature">
          <div className="titleFont text-center" >
            <h2>Nos différences</h2>
          </div>
          <div className="mainFeaturesCard">
            <Row>
              <Col xs={12} sm={3}>
                <div className="featuresCard">
                  <div className="featuresCardCircle">
                    <img className="img-fluid mx-auto" src={Feature1} alt="OurFeature" />
                  </div>
                  <div className="featuresCardContain">
                    <h3>un réseau interne et externe</h3>
                    <h6>
                      de 30 consultants et formateurs experts reconnue 
                      dans leurs disciplines totalités 
                      des volets RH Management 
                    </h6>
                    <details className="details">
                      <summary className="summary">
                        <span id="open">Savoir plus</span>
                        <span id="close">Moins</span>
                      </summary>

                      <h6 className="maskbtnOpenText">
                      / Coaching / performance
                      </h6>
                    </details>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={3}>
                <div className="featuresCard" >
                  <div className="featuresCardCircle">
                    <img className="img-fluid mx-auto" src={Feature2} alt="OurFeature" />
                  </div>
                  <div className="featuresCardContain">
                    <h3>Un cabinet à Dimension International</h3>
                    <h6>
                    Un cabinet à Dimension International pratique à travers l'emission de nos clients ( Maroc Afrique Europe ) 
                    </h6>
                    <details className="details">
                      <summary className="summary">
                        <span id="open">Savoir plus</span>
                        <span id="close">Moins</span>
                      </summary>
                      <h6 className="maskbtnOpenText">
                      une équipe interne polyvalente 
                    Français / Arabe / Anglais / Espagnole prête 
                    à relevé des défis à l'échelle internationale
                      </h6>
                    </details>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={3}>
                <div className="featuresCard">
                  <div className="featuresCardCircle">
                    <img className="img-fluid mx-auto" src={Feature3} alt="OurFeature" />
                  </div>
                  <div className="featuresCardContain">
                    <h3> Une équipe engagée</h3>
                    <h6>
                    Une équipe engagée séléctionné à la fois sur son niveau de compétence 
                    et ses valeurs pationnées par 
                    les RH et dévouées 
                    </h6>
                    <details className="details">
                      <summary className="summary">
                        <span id="open">Savoir plus</span>
                        <span id="close">Moins</span>
                      </summary>

                      <h6 className="maskbtnOpenText">
                      à la satisfaction de nos clients
                      </h6>
                    </details>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={3}>
                <div className="featuresCard">
                  <div className="featuresCardCircle">
                    <img className="img-fluid mx-auto" src={Feature4} alt="OurFeature" />
                  </div>
                  <div className="featuresCardContain">
                    <h3>Une connaissance des meilleures pratiques</h3>
                    <h6>
                    Une connaissance des meilleures pratiques RH au niveau nationale et International et 
                    </h6>
                    <details className="details">
                      <summary className="summary">
                        <span id="open">Savoir plus</span>
                        <span id="close">Moins</span>
                      </summary>

                      <h6 className="maskbtnOpenText">
                      une application rigoureuse et des processus de gestion
                    garantissant un niveau d'efficience maximal
                      </h6>
                    </details>
                  </div>
                </div>
              </Col>
            </Row>
            <button className="homeBannerBtn ourSurvice"><Link to="/service">Voir Plus</Link></button>
          </div>
        </div>
      </div>
    </>
  );
}
